import React from "react";
import LessonLayout from "~/layouts/lesson";
import Introduction from "~/layouts/introduction";

import BSCSLogo from '~/images/lesson_5_opener.jpg';



const Page = () => {
  return (
      <LessonLayout
        current_step={0}
        lesson={"Lesson 5"}
        color={"hs"}
        total_count={7}
        module_title="Putting Your Rubric to Work"
      >
        <Introduction
          alt="tablet"
          image={BSCSLogo}
          description="In the last four lessons, you have worked hard to put your rubric together. Now it is time to test it out using websites or other information that you chose yourself."
        />

      </LessonLayout>
  )
}

export default Page;
